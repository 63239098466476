import axios from 'axios';

const datas = [
	{
		name: 'movie_0',
		url: '/assets/videos/0',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},
	{
		name: 'movie_1',
		url: '/assets/videos/1',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},
	{
		name: 'movie_2',
		url: '/assets/videos/2',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},
	{
		name: 'movie_3',
		url: '/assets/videos/3',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},
	{
		name: 'movie_4',
		url: '/assets/videos/4',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},
	{
		name: 'movie_5',
		url: '/assets/videos/5',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},
	{
		name: 'movie_6',
		url: '/assets/videos/6',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},
	{
		name: 'movie_7',
		url: '/assets/videos/7',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},
	{
		name: 'movie_8',
		url: '/assets/videos/8',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},
	{
		name: 'movie_9',
		url: '/assets/videos/9',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},
	{
		name: 'movie_10',
		url: '/assets/videos/10',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},
	{
		name: 'movie_11',
		url: '/assets/videos/11',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},
	{
		name: 'movie_12',
		url: '/assets/videos/12',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},
	{
		name: 'movie_13',
		url: '/assets/videos/13',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},
	{
		name: 'movie_14',
		url: '/assets/videos/14',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},
];

export class Loader {
	public quality: string;
	public datas: any;
	public total: number;
	public progress: number;
	private debug: boolean;

	private lastCall: number;

	constructor(quality: string) {
		this.quality = quality;
		this.datas = datas;
		this.total = 0;
		this.progress = 0;
		this.debug = false;
		this.lastCall = Date.now();

		this.download();
	}

	updateLoadingDetails() {
		if (Date.now() - this.lastCall > 200) {
			this.updateLoading();
			this.lastCall = Date.now();
		}
	}

	writeDebug = (data: string) => {
		if (this.debug) {
			let debugLoader = document.getElementById('debugLoader');

			if (debugLoader) {
				debugLoader.innerHTML += data;
			}
		}
	};

	clearDebug = () => {
		if (this.debug) {
			let debugLoader = document.getElementById('debugLoader');

			if (debugLoader) {
				debugLoader.innerHTML = '';
			}
		}
	};

	addRessources = (list: any) => {};

	waitReady = () =>
		new Promise((resolve, reject) => {
			let lastTime = Date.now();
			let tmp = this.progress;

			let interval = setInterval(() => {
				if (this.progress >= 100) {
					clearInterval(interval);
					resolve('Assets are ready to use.');
				}
				if (tmp === this.progress) {
					if (Date.now() - lastTime > 20000) {
						lastTime = Date.now();
						tmp = 0;
						setTimeout(() => {
							//console.log("DL AGAIN")
							//this.download();
						}, 1000);
						//clearInterval(interval);
						//reject('Assets loading failed');
					}
				} else {
					tmp = this.progress;
				}
			}, 500);
		});

	updateLoading = () => {
		let total = 0;
		let progress = 0;
		for (let a of this.datas) {
			total += a.total;
			progress += a.progress;
		}
		console.log('progress/total', progress, total);
		this.progress = (progress / total) * 100;

		// if(this.setProgress){
		// 	console.log(this.progress)
		// 	this.dispatch(this.setProgress(this.progress))
		// }

		this.clearDebug();

		let loadingOver = true;

		for (let i = 0; i < this.datas.length; i++) {
			if (!this.datas[i].ready) {
				loadingOver = false;
				this.writeDebug(this.datas[i].name + ' NO<br />');
			} else {
				//this.writeDebug(this.assetsList[i].name+" YES<br />")
				//this.writeDebug(this.progress+"<br />")
			}
		}

		if (loadingOver) {
			this.progress = 100;
			//this.writeDebug("OVER<br />")

			let total = 0;

			for (let i = 0; i < this.datas.length; i++) {
				console.log(this.datas[i].name + ' - ' + this.datas[i].total / 1000000 + '');
				total += this.datas[i].total;
			}

			console.log('TOTAL : ' + total / 1000000);
		} else {
			this.writeDebug(this.progress + '<br />');
		}

		//this.writeDebug(this.progress+"<br />")
	};

	download = () => {
		this.clearDebug();

		let _this = this;

		let getSize = function (index: number) {
			var xhr = new XMLHttpRequest();
			xhr.open('HEAD', _this.datas[index].url + _this.quality + '.mp4', true);
			xhr.onreadystatechange = function () {
				if (this.readyState === this.DONE) {
					let size = xhr.getResponseHeader('Content-Length');
					if (size) {
						_this.datas[index].total = parseInt(size);
						_this.total += parseInt(size);
					} else {
						_this.datas[index].total = 0;
					}
				}
			};
			xhr.send();
		};

		for (let i = 0; i < this.datas.length; i++) {
			if (!this.datas[i].ready) {
				if (this.datas[i].url === '') {
					this.datas[i].ready = true;
					this.datas[i].total = 0;
				} else if (this.datas[i].total === undefined || this.datas[i].total === 0) {
					getSize(i);
				}

				// Tcheck in public folder
				axios({
					url: process.env.PUBLIC_URL + this.datas[i].url + _this.quality + '.mp4',
					method: 'GET',
					responseType: 'blob',
				})
					.then((response: any) => {
						const href = URL.createObjectURL(response.data);

						this.datas[i].progress = this.datas[i].total;
						this.datas[i].file = href;
						this.datas[i].ready = true;
						this.updateLoading();
					})
					.catch(() => {
						// If don't find... download
						axios({
							url: 'https://sncf.tmscp.io/' + this.datas[i].url + _this.quality + '.mp4',
							method: 'GET',
							responseType: 'blob'
						}).then((response: any) => {
							const href = URL.createObjectURL(response.data);
							this.datas[i].progress = this.datas[i].total;
							this.datas[i].file = href;
							this.datas[i].ready = true;
							this.updateLoading();
						});
					});
			}
		}

		console.log('assetlist', this.datas);
	};
}
