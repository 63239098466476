import { ipcMain } from 'electron';
import React, { ButtonHTMLAttributes, useEffect, useRef, useState } from 'react';

import * as THREE from 'three';

import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { Loader } from '../loader/loadRessources';

import './main.css';

export default function Main() {
	let data = require('./localization.json');

	let sound = require('./loop.mp3');

	var audio_loop = new Audio(sound);
	audio_loop.loop = true;
	audio_loop.volume = .4;

	const _interval = useRef<any>();
	const [quality, setQuality] = useState<string | undefined>();
	const [loader, setLoader] = useState<Loader | undefined>();

	useEffect(() => {
		_interval.current = setInterval(() => {
			if ((window as any).electronAPI?.sendPackaged) {
				console.log('SEND QUALITY 4K');
				setQuality('_4K');
			}
		}, 1000);

		return () => clearInterval(_interval.current);
	}, []);

	useEffect(() => {
		if ((window as any).electronAPI?.sendPackaged) {
			document.getElementById('setQuality')!.style.display = 'none';
		}
	}, []);


	useEffect(() => {
		if (quality) {
			clearInterval(_interval.current);
			setLoader(new Loader(quality));
		}
	}, [quality]);

	useEffect(() => {
		if (quality && loader) {
			let intervalPercent = setInterval(() => {
				if (loader.progress >= 100) {
					clearInterval(intervalPercent);
				} else {
					let percentLoad = document.getElementById('loader-progressBarContent');

					if (percentLoad) {
						percentLoad.style.width = loader.progress + '%';
					}
				}
			}, 200);

			loader.waitReady().then(() => {
				setLoading(false);
			});
		}
	}, [loader]);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!loading) {
			let isUserInteracting = false,
				lon = 0,
				lat = 0,
				phi = 0,
				theta = 0,
				onPointerDownPointerX = 0,
				onPointerDownPointerY = 0,
				onPointerDownLon = 0,
				onPointerDownLat = 0;

			const max_rotation_angle_rad = 3.14 / 4;

			const distance = 50;

			const LocalizationData = data;

			var FOV_value = 60;

			var choice = 0;
			var level = 1;
			const nb_of_level = 3;
			var OnPlayVideoNumber = 0;

			const container = document.getElementById('container');

			let camera = new THREE.PerspectiveCamera(FOV_value, window.innerWidth / window.innerHeight, 1, 1100);

			let scene = new THREE.Scene();

			const geometry = new THREE.SphereGeometry(500, 60, 40);
			geometry.scale(-1, 1, 1);

			const ChoiceRenderer = new CSS2DRenderer();
			ChoiceRenderer.setSize(window.innerWidth, window.innerHeight);
			ChoiceRenderer.domElement.style.position = 'absolute';
			ChoiceRenderer.domElement.style.top = '0px';
			ChoiceRenderer.domElement.style.pointerEvents = 'true';
			document.body.appendChild(ChoiceRenderer.domElement);

			const fullscreen = document.createElement('button');
			fullscreen.className = 'btnFullScreen';
			fullscreen.addEventListener('click', (event) => {
				if (document.fullscreenElement) {
					document.exitFullscreen();
					fullscreen.className = 'btnFullScreen';
				} else {
					document.documentElement.requestFullscreen();
					fullscreen.className = 'btnExitFullScreen';
				}
			});
			const divFullScreen = document.createElement('div');
			divFullScreen.appendChild(fullscreen);
			const divFullScreenContainer = new CSS2DObject(divFullScreen);

			const choiceOne = document.createElement('button');
			choiceOne.innerText = LocalizationData[0].ChoosePanel_choiceA;
			choiceOne.className = 'button_choice';
			choiceOne.addEventListener('click', (event) => {
				PlayVideo(1);
			});

			const choiceTwo = document.createElement('button');
			choiceTwo.innerText = LocalizationData[0].ChoosePanel_choiceB;
			choiceTwo.className = 'button_choice';
			choiceTwo.addEventListener('click', (event) => {
				PlayVideo(2);
			});

			const choiceText = document.createElement('h1');
			choiceText.innerText = LocalizationData[0].ChoosePanel_question;
			choiceText.className = 'choiceText';

			const div = document.createElement('div');
			div.className = 'div_choice';
			div.appendChild(choiceText);
			div.appendChild(choiceOne);
			div.appendChild(choiceTwo);

			const divContainer = new CSS2DObject(div);

			const endPanel = document.createElement('button');
			endPanel.innerText = ' Restart ↺ ';
			endPanel.className = 'btnReload';
			endPanel.addEventListener('click', (event) => {
				FOV_value = 60;
				choice = 0;
				level = 1;
				OnPlayVideoNumber = 0;
				scene.remove(enddivContainer);
				video.src = loader?.datas.find((m: any) => m.name === 'movie_' + 0).file;
				video.play();
				choiceOne.innerText = LocalizationData[0].ChoosePanel_choiceA;
				choiceTwo.innerText = LocalizationData[0].ChoosePanel_choiceB;
				choiceText.innerText = LocalizationData[0].ChoosePanel_question;
			});

			const enddiv = document.createElement('div');
			enddiv.appendChild(endPanel);
			const enddivContainer = new CSS2DObject(enddiv);

			const fadePanel = document.createElement('div');
			fadePanel.className = 'fadePanel';
			const fadePanelContainer = new CSS2DObject(fadePanel);

			const StartVideo = document.createElement('button');
			StartVideo.innerText = ' ▶';
			StartVideo.className = 'btnPlay';
			StartVideo.addEventListener('click', (event) => {
				video.play();
				scene.remove(divStartVideoContainer);
			});
			const divStartVideo = document.createElement('div');
			divStartVideo.appendChild(StartVideo);
			const divStartVideoContainer = new CSS2DObject(divStartVideo);

			const restartVideo = document.createElement('button');
			restartVideo.className = 'btnReplay';
			restartVideo.addEventListener('click', (event) => {
				ReplayVideo();
			});
			const divReplay = document.createElement('div');
			divReplay.appendChild(restartVideo);
			const divReplayContainer = new CSS2DObject(divReplay);

			const video = document.getElementById('video') as HTMLVideoElement;
			video.onended = function (e) {
				OpenChoicePanel();
			};
			video.muted = false;
			
			if ((window as any).packaged !== 'packaged') {
				video.src = loader?.datas.find((d: any) => d.name === 'movie_0').file;
			}

			if ((window as any).packaged !== 'packaged') {
				video.pause();
				scene.add(divStartVideoContainer);
			}

			if ((window as any).electronAPI?.sendPackaged) {
				video.src = loader?.datas.find((d: any) => d.name === 'movie_0').file;
				video.play();
				scene.remove(divStartVideoContainer);
			}

			const texture = new THREE.VideoTexture(video);
			const material = new THREE.MeshBasicMaterial({ map: texture });

			const mesh = new THREE.Mesh(geometry, material);
			scene.add(mesh);

			let renderer = new THREE.WebGLRenderer();
			renderer.setPixelRatio(window.devicePixelRatio);
			renderer.setSize(window.innerWidth, window.innerHeight);

			if (container) {
				container.appendChild(renderer.domElement);
			}

			document.addEventListener('pointerdown', onPointerDown);
			document.addEventListener('pointermove', onPointerMove);
			document.addEventListener('pointerup', onPointerUp);

			window.addEventListener('resize', onWindowResize);
			window.addEventListener('wheel', (event) => {
				const delta = Math.sign(event.deltaY);
				FOV_value = FOV_value + delta;
				if (FOV_value > 30 && FOV_value < 120) {
					camera.fov = FOV_value;
					camera.updateProjectionMatrix();
				} else {
					FOV_value = FOV_value - delta;
				}
			});

			scene.add(divFullScreenContainer);

			function OpenChoicePanel() {
				if (level < nb_of_level + 1) {
					audio_loop.play();
					scene.add(divContainer);
					scene.add(divReplayContainer);
				} else {
					scene.add(enddivContainer);
				}
			}

			function PlayVideo(choice_binary: any) {
				let VideoNumber = 0;
				VideoNumber = choice * 2 + choice_binary;
				choice = choice_binary + choice * level;
				level = level + 1;
				fadeIn();
				scene.remove(divContainer);
				scene.remove(divReplayContainer);
				if (level < nb_of_level + 1) {
					choiceOne.innerText = LocalizationData[choice].ChoosePanel_choiceA;
					choiceTwo.innerText = LocalizationData[choice].ChoosePanel_choiceB;
					choiceText.innerText = LocalizationData[choice].ChoosePanel_question;
				}
				OnPlayVideoNumber = VideoNumber;
				video.src = loader?.datas.find((m: any) => m.name === 'movie_' + VideoNumber).file;
				audio_loop.pause();
				video.play();
				fadeOut();
			}

			function ReplayVideo() {
				fadeIn();
				scene.remove(divContainer);
				scene.remove(divReplayContainer);
				video.src = loader?.datas.find((m: any) => m.name === 'movie_' + OnPlayVideoNumber).file;
				video.play();
				fadeOut();
			}

			function fadeIn() {
				scene.add(fadePanelContainer);
				fadePanel.animate({ opacity: 1 }, 500);
				setTimeout(function () {
					fadePanel.style.opacity = '1';
				}, 500);
			}

			function fadeOut() {
				fadePanel.animate({ opacity: 0 }, 500);
				setTimeout(function () {
					fadePanel.style.opacity = '0';
					scene.remove(fadePanelContainer);
				}, 500);
			}

			function onWindowResize() {
				camera.aspect = window.innerWidth / window.innerHeight;
				camera.updateProjectionMatrix();

				renderer.setSize(window.innerWidth, window.innerHeight);
				ChoiceRenderer.setSize(window.innerWidth, window.innerHeight);
			}

			function onPointerDown(event: MouseEvent) {
				isUserInteracting = true;

				onPointerDownPointerX = event.clientX;
				onPointerDownPointerY = event.clientY;

				onPointerDownLon = lon;
				onPointerDownLat = lat;
			}

			function onPointerMove(event: MouseEvent) {
				if (isUserInteracting === true) {
					lon = (onPointerDownPointerX - event.clientX) * 0.1 + onPointerDownLon;
					lat = (onPointerDownPointerY - event.clientY) * 0.1 + onPointerDownLat;

					let max_angle_deg = THREE.MathUtils.radToDeg(max_rotation_angle_rad);
					if (lon > max_angle_deg) {
						lon = max_angle_deg;
					}
					if (lon < -max_angle_deg) {
						lon = -max_angle_deg;
					}
				}
			}

			function onPointerUp() {
				isUserInteracting = false;
			}

			function animate() {
				ChoiceRenderer.render(scene, camera);
				requestAnimationFrame(animate);
				update();
			}

			function update() {
				lat = Math.max(-85, Math.min(85, lat));
				phi = THREE.MathUtils.degToRad(90 - lat);
				theta = THREE.MathUtils.degToRad(lon);

				if (theta > 0) {
					if (theta > max_rotation_angle_rad) {
						theta = max_rotation_angle_rad;
					}
				}
				if (theta < 0) {
					if (theta < -max_rotation_angle_rad) {
						theta = -max_rotation_angle_rad;
					}
				}

				camera.position.x = distance * Math.sin(phi) * Math.cos(theta);
				camera.position.y = distance * Math.cos(phi);
				camera.position.z = distance * Math.sin(phi) * Math.sin(theta);

				camera.lookAt(0, 0, 0);

				renderer.render(scene, camera);
			}

			animate();
		}
	}, [loading]);
	  
	return (
		<React.Fragment>
			{!quality && <div id='setQuality' className='setQuality'>
				<div className='qualityText'>En quelle qualité voulez-vous charger les vidéos ?</div>
				<div className='buttons'>
					<button
						className='button_choice'
						onClick={() => {
							setQuality('_2K');
						}}
					>
						2K
					</button>
					<button
						className='button_choice'
						onClick={() => {
							setQuality('_4K');
						}}
					>
						4K
					</button>
				</div>
			</div>}
			{loading && (
				<div id='loader' className='loader'>
					<div className='loader-progressBarContainer'>
						<div id='loader-progressBarContent' className='loader-progressBarContent'></div>
					</div>
				</div>
			)}
			{!loading && (
				<React.Fragment>
					<div id='container' className='main'></div>
					<video id='video' className='video' crossOrigin='anonymous' playsInline autoPlay={true}></video>

					<div id='fadePanel' className='fadePanel'></div>
				</React.Fragment>

			)}
		</React.Fragment>
	);
}
